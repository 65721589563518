import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { SymbolsPage, Comfirmation, Results } from "./LayoutSessions";

const Sessions = ({ soundOn, symbols, target }) => {
  const navigate = useNavigate();
  const handleClick = (location) => {
    navigate(location);
  };
  const [session, setSession] = useState("symbols");
  return (
    <>
      <SymbolsPage
        symbols={symbols}
        setSession={setSession}
        display={session === "symbols" ? "show" : null}
        navigate={handleClick}
      />
      {session === "confirmation" && (
        <Comfirmation
          session={session}
          setSession={setSession}
          navigate={handleClick}
        />
      )}
      {session === "results" && (
        <Results result={target} soundOn={soundOn} navigate={handleClick} />
      )}
    </>
  );
};

export default Sessions;
