import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import {
  Home,
  Start,
  Secret,
  Options,
  Symbol,
} from "./components/all-components";
import useSoundState from "./hooks/useSoundState";
import useSound from "./hooks/useSound";

const App = () => {
  const [soundOn, setSoundOn] = useSoundState();
  useSound("click");
  return (
    <div className="App">
      <div className="container">
        <audio
          preload="auto"
          id="sound"
          style={{ display: "none" }}
          on={soundOn ? "on" : null}
        ></audio>
        <Router>
          <Routes>
            <Route path="/start">
              <Route index element={<Start soundOn={soundOn} />} />
              <Route path=":id" element={<Start soundOn={soundOn} />} />
            </Route>
            <Route path="/secret" element={<Secret soundOn={soundOn} />} />
            <Route
              path="/options"
              element={<Options soundOn={soundOn} setSoundOn={setSoundOn} />}
            />
            <Route path="/symbols" element={<Symbol soundOn={soundOn} />} />
            <Route path="/*" element={<Home soundOn={soundOn} />}></Route>
          </Routes>
        </Router>
      </div>
    </div>
  );
};

export default App;
