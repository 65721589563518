import { useNavigate } from "react-router-dom";
import LayoutDefault from "./LayoutDefault";
import useSound from "../hooks/useSound";

const Home = ({ soundOn }) => {
  const navigate = useNavigate();
  const handleClick = (location) => {
    navigate(location);
  };
  useSound("click");
  return (
    <>
      <LayoutDefault
        Main={
          <div className="msg-wrapper home-navs">
            <button onClick={() => handleClick("/start")}>Start</button>
            <button onClick={() => handleClick("/secret")}>Secret</button>
            <button onClick={() => handleClick("/more-games")}>
              More games
            </button>
            <button onClick={() => handleClick("/options")}>Options</button>
          </div>
        }
      />
    </>
  );
};

export default Home;
