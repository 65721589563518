import React from "react";
import randomKey from "../module/random-keys";
import LayoutDefault from "./LayoutDefault";
import useSound from "../hooks/useSound";

const SymbolsPage = ({ display, symbols, setSession, navigate }) => {
  const getRandomKey = randomKey();
  useSound("click");
  return (
    <>
      <div
        className="symbol-page"
        style={{ display: display ? "flex" : "none" }}
      >
        <h1>Find the symbol</h1>
        <div className="symbols-wrapper">
          <div className="symbols">
            {symbols.map(([num, symbol]) => (
              <div className="symbol-box" key={getRandomKey()}>
                <span>{num}</span>
                <img src={symbol.path} alt={symbol.name} />
              </div>
            ))}
          </div>
        </div>
        <div className="symbol-navs">
          <button onClick={() => navigate("/start/3")}>Back</button>
          <button onClick={() => setSession("confirmation")}>Continue</button>
        </div>
      </div>
    </>
  );
};
const Comfirmation = ({ setSession }) => {
  useSound("click");
  return (
    <>
      <LayoutDefault
        Main={
          <div className="msg-wrapper confirmation">
            <h2>Continue</h2>
            <ul className="info-list">
              <li>
                Have you memorized the symbol corresponding to your number.
              </li>
              <li>Are you sure?</li>
            </ul>
          </div>
        }
        Footer={
          <div className="navs confirmation-navs">
            <button onClick={() => setSession("results")}>Yes</button>
            <button onClick={() => setSession("symbols")}>No</button>
          </div>
        }
      />
    </>
  );
};
const Results = ({ soundOn, result, navigate }) => {
  useSound("roar", "load");
  useSound("click");
  return (
    <>
      <LayoutDefault
        Main={
          <div className="msg-wrapper results">
            <h2>Projecting your thoughts</h2>
            <div className="result">
              <h3>{result.name}</h3>
              <img src={result.path} alt={result.name} />
            </div>
          </div>
        }
        Footer={
          <>
            <button onClick={() => navigate("/")}>Main Menu</button>
          </>
        }
      />
    </>
  );
};

export { SymbolsPage, Comfirmation, Results };
