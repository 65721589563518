import { useEffect } from "react";
import click from "../assets/click.mp3";
import crystal from "../assets/crystal.mp3";
import roar from "../assets/demon-roar.mp3";

const useSound = (name, trigger) => {
  useEffect(() => {
    const soundFile =
      name === "click" ? click : [crystal, roar][Math.floor(Math.random() * 2)];
    const addSound = () => {
      const audio = document.getElementById("sound");
      if (!audio?.play || !audio.getAttribute("on")) return;
      audio.src = soundFile;
      setTimeout(() => audio.play());
    };
    if (trigger === "load") return addSound();
    const btns = document.querySelectorAll("button");
    btns.forEach((btn) => btn.addEventListener("click", addSound));
    return () =>
      btns.forEach((btn) => btn.removeEventListener("click", addSound));
  });
};

export default useSound;
